.text-light-primary {
  color: #A6C5F7 !important;
}

.bg-light-primary {
  background: #A6C5F7!important;
}

.btn-outline-light-primary {
  border-color: #A6C5F7!important;
}

*,*::after,*::before{
-webkit-box-sizing:border-box;box-sizing:border-box
}

img{max-width:100%}

@media only screen and (min-width:1170px){
.cd-is-hidden{visibility:hidden}
}

.cd-timeline{
overflow:hidden;
margin:2em auto;
}

.cd-timeline__container{
position:relative;
width:90%;
max-width:1170px;
margin:0 auto;
padding:2em 0;
}

.cd-timeline__container::before{
content:'';
position:absolute;
top:0;
left:18px;
height:100%;
width:4px;
background:#d7e4ed;
}

@media only screen and (min-width: 1170px) {
  .cd-is-hidden {
    visibility: hidden;
  }
}

.cd-timeline {
  overflow: hidden;
  margin: 2em auto;
}

.cd-timeline__container {
  position: relative;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 2em 0;
}

.cd-timeline__container::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #FFF;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .cd-timeline__container::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline_content {
  width: 80%;
  margin: auto;
}

.cd-timeline__block {
  position: relative;
  margin: 2em 0;
}

.cd-timeline__block:after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.cd-timeline__block:first-child {
  margin-top: 0;
}

.cd-timeline__block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline__block {
    margin: 4em 0;
  }
}

.cd-timeline__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-shadow:0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05);
        box-shadow:0 0 0 4px #fff,inset 0 2px 0 rgba(18, 38, 63, 0.03),0 3px 0 4px rgba(18, 38, 63, 0.03);
  color: #fff;
}

.cd-timeline__img span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 1.3em;
}

.cd-timeline__img.cd-timeline__img--picture {
  background: #75ce66;
}

.cd-timeline__img.cd-timeline__img--movie {
  background: #c03b44;
}

.cd-timeline__img.cd-timeline__img--location {
  background: #f0ca45;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline__img {
    left: 50%;
    transform: translateX(-50%);
  }
  .cd-timeline__img.cd-timeline__img--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
            animation: cd-bounce-1 0.6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.cd-timeline__content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
          box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.cd-timeline__content .align-items-center:nth-child(2){
  margin-top: 1rem;
}

.cd-timeline__content:after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.cd-timeline__content::before {
  /* triangle next to content block */
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}

.cd-timeline__read-more,
.cd-timeline__date {
  display: inline-block;
}

.cd-timeline__read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}

.cd-timeline__read-more:hover {
  background-color: #bac4cb;
}

.cd-timeline__date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}

@media only screen and (min-width: 768px) {
  .cd-timeline__read-more,
  .cd-timeline__date {
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline__content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  .cd-timeline__content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .cd-timeline__read-more {
    float: left;
  }
  .cd-timeline__date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__content {
    float: right;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__read-more {
    float: right;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cd-timeline__content.cd-timeline__content--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
            animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cd-timeline__block:nth-child(even) .cd-timeline__content.cd-timeline__content--bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
            animation: cd-bounce-2-inverse 0.6s;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.cd-timeline__content{
  transition-duration: 200ms;
}

.cd-timeline__content:hover {
  box-shadow:0 0 5px #85b1e4;

}