.select2-container .select2-selection--single .select2-selection__rendered {
    overflow: hidden;
    white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px;
    padding-right: 0px;
}

.select2-container--default .select2-selection--single {
    border: 1px solid #d2ddec;
    border-radius: .375rem;
    height: calc(2.40625rem + 2px);
    padding: .5rem .75rem;
    color: #12263f;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: inherit;
    line-height: inherit;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
    top: 0px;
    width: 30px;
}

.select2-dropdown {
    border: 1px solid #d2ddec;
}