//
// main-content.scss
// Dashkit component
//

// Main content
//
// General styles

.main-content {

  .container-fluid {

    @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
      padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important; 
    }
  }
}


// Main content offset
//
// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  
  @each $breakpoint, $dimension in $grid-breakpoints {
      
    &-#{$breakpoint} {
        
      @include media-breakpoint-up(#{$breakpoint}) {

        // Left

        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        }

        // Right

        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}