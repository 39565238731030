// 
// card.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

.card {
  margin-bottom: $spacer;
  box-shadow: $card-box-shadow;
}

.card-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  min-height: $card-header-min-height;
  padding-top: $card-spacer-y / 2;
  padding-bottom: $card-spacer-y / 2;

  > * {
    flex: 1;
  }
}

.card-title {
  margin-bottom: .5rem;
}


//
// Dashkit ===================================
//

// Card header
//
// Make sure the card header is always the same height with its content
// centered vertically

.card-header-title {
  margin-bottom: 0;
}

.card-header-tabs {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: -$card-spacer-y / 2;
  margin-left: 0;
  margin-right: 0;
}

.card-header-tabs .nav-link {
  padding-top: calc((#{$card-header-min-height} - 1em * #{$line-height-base}) / 2) !important;
  padding-bottom: calc((#{$card-header-min-height} - 1em * #{$line-height-base}) / 2) !important;
}


// Card table
//
// Make sure the card table content is aligned with the rest of the
// card content

.card-table {
  margin-bottom:0;

  thead th {
    border-top-width: 0;
  }
  
  thead th, tbody td {

    &:first-child {
      padding-left: $card-spacer-x !important;
    }
    &:last-child {
      padding-right: $card-spacer-x !important;
    }
  }
}

.card > .table-responsive > .card-table,
.card > .card-table {

  > thead, > tbody, > tfoot {

    &:first-child {

      > tr:first-child {

        > th, > td {

          &:first-child {
            border-top-left-radius: $card-border-radius;
          }
          &:last-child {
            border-top-right-radius: $card-border-radius;
          }
        }
      }
    }
  }
}


// Card avatar
//
// Moves card avatar up by 50%

.card-avatar {
  display: block !important;
  margin-left: auto; margin-right: auto;
  margin-bottom: $card-spacer-y;
}
.card-avatar-top {
  margin-top: -($card-spacer-x + $avatar-size-base / 2);
}
.card-avatar-top.avatar-xs {
  margin-top: -($card-spacer-x + $avatar-size-xs / 2);
}
.card-avatar-top.avatar-sm {
  margin-top: -($card-spacer-x + $avatar-size-sm / 2);
}
.card-avatar-top.avatar-lg {
  margin-top: -($card-spacer-x + $avatar-size-lg / 2);
}
.card-avatar-top.avatar-xl {
  margin-top: -($card-spacer-x + $avatar-size-xl / 2);
}
.card-avatar-top.avatar-xxl {
  margin-top: -($card-spacer-x + $avatar-size-xxl / 2);
}


// Card dropdown
//
// Places dropdowns in the top right corner

.card-dropdown {
  position: absolute;
  top: $card-spacer-x;
  right: $card-spacer-x;
}


// Card inactive

.card-inactive {
  border-color: $border-color;
  border-style: dashed;
  background-color: transparent;
  box-shadow: none;
}