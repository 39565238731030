.row-nowrap {
  white-space: nowrap;
}

.documents-footer td,
.certificates-footer td,
.records-footer tr {
  text-align: center;
  background-color: #f9fbfd;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: #95aac9;
  border-bottom-width: 1px;
}

.financing-logos {
  a.feder-bar {
    width: 100%;
  }
  a {
    width: 25%;
  }
}

.nav-tabs-scroll {
  position: relative;
  overflow-x: auto;
}

.homepage-financing-logos {
  margin-top: 3em;
}

.custom-file-label {
  overflow: hidden;
}

.loading-dropzone {
  background-color: #edf2f9;
  opacity: 0.5;
}

.select2-container .select2-search--inline {
  display: block;
}

.select2-container .select2-search--inline .select2-search_field {
  margin: 0 0 0.25rem 0.25rem;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: rgb(227, 235, 246);
    //background: linear-gradient(to right, transparent, rgb(227, 235, 246), transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #12263f;
    background-color: #ffffff;
  }
}
.optProject {
  font-weight: bold;
  font-style: italic;
}
.optTask {
  padding-left: 25px;
}
.optSubtask {
  padding-left: 45px;
}
.btn-primary-radio {
  color: #fff;
  background-color: #59a0ff;
  border-color: #59a0ff;
}
.btn-primary-radio.active {
  box-shadow: 0 0 0 0.15rem rgba(76, 143, 233, 0.5);
}
.awarded {
  border-color: #00d97e;
  border-style: solid;
  padding: 0.5%;
  margin-left: 1%;
  border-radius: 5px;
}

.page-link-pagination {
  display: inline;
}

.tox-notifications-container {
  display: none;
}

.menu-active {
  background-color: #a5c5f6;
}

.menu-active a {
  color: white !important;
}

.span-num-nots {
  width: 1rem;
  height: 1rem;
  background: red;
  border-radius: 20px;
  text-align: center;
  z-index: 1;
  right: -4px;
  top: -2px;
  position: absolute;
  font-size: 10px;
  text-align: center;
}

.li-num-nots {
  margin-left: 35px;
  width: 1.25rem;
  height: 1.25rem;
  background: red;
  border-radius: 20px;
  padding-top: 1px;
  font-size: 12px;
  text-align: center;
  color: white;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

.nav-tabs li a {
  cursor: pointer;
}

.progress-container {
  left: 18%;
  padding-bottom: 1.5rem;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 14px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

.progressbar li a {
  color: #7d7d7d;
  margin: 0px;
  display: block;
  width: 100%;
  height: 100%;
}

.progressbar li form button {
  color: #7d7d7d;
  margin: 0px;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

/*.progressbar li a:before {
  width: 45px;
  height: 45px;
  content: counter(step);
  counter-increment: step;
  line-height: 42px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}*/
.progressbar li a:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 22px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child a:after {
  content: none;
}

.progressbar li.active a span {
  border-color: #2c7be5 !important;
  background-color: #2c7be5 !important;
  color: white !important;
}
/*.progressbar li.active + li a:after {
  background-color: #2C7BE5;
}*/

.step-circle {
  width: 45px;
  height: 45px;
  line-height: 42px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.approve-deliverable:hover {
  background-color: #edf2f9;
}

.reopen-deliverable:hover {
  background-color: #edf2f9;
}

.div-click {
  cursor: pointer !important;
}

.contract-approve {
  border-color: #7d7d7d !important;
  transition: background-color 300ms, border-color 300ms;
}

.contract-approve:hover {
  border-color: #2c7be5 !important;
  background-color: #2c7be5 !important;
  color: white !important;
}

.nav-link {
  padding: 0.5rem 1rem !important;
}

.custom-checkbox-toggle .custom-control-label::after {
  background: #eee !important;
}

a.dropdown-ellipses > i:hover {
  color: #3988ff;
}

//GANTT
.gantt_task_line,
.gantt_line_wrapper {
  margin-top: -9px;
}
.gantt_side_content {
  margin-bottom: 7px;
}
.gantt_task_link .gantt_link_arrow {
  margin-top: -12px;
}
.gantt_side_content.gantt_right {
  bottom: 0;
}

.general-endeavorevents {
  overflow: hidden;
  margin-right: -15px;
  margin-left: -15px;
}
.general-endeavorevents div {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.general-endeavorevents div {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.col-0 {
  flex: 0 0 0%;
  max-width: 0%;
}

.baseline {
  position: absolute;
  border-radius: 4px;
  opacity: 0.6;
  margin-top: -7px;
  height: 12px;
  background: #afb8e2;
}

.gantt-task-modal {
  max-height: 650px;
  overflow-y: auto;
}

#my-form {
  position: fixed;
  top: 15%;
  left: 25%;
  z-index: 1031;
  width: 50%;
  display: none;
  padding: 10px 10px 10px 10px;
  min-height: 500px;
  max-height: 800px;
}

.modal-xxl {
  max-width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
}

.overdue-indicator {
  width: 22px;
  margin-top: 10px;
  height: 22px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 14px;
  color: white;
  background: rgb(255, 60, 60);
  line-height: 22px;
  text-align: center;
  font-size: 20px;
  min-width: 22px;
}

.custom-check {
  position: relative;
  cursor: pointer;
  color: #666;
  font-size: 30px;
  vertical-align: middle !important;
  margin: 0px;
}

.custom-checkbox {
  position: absolute;
  right: 9000px;
}

.form-check-div {
  display: inline;
}

.custom-checkbox + .label-text:before {
  content: '\f096';
  font-family: 'FontAwesome';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 5px;
}

.custom-checkbox:checked + .label-text:before {
  content: '\f14a';
  color: #2c7be5;
  animation: effect 250ms ease-in;
}

.custom-checkbox:disabled + .label-text {
  color: #aaa;
}

.custom-checkbox:disabled + .label-text:before {
  content: '\f0c8';
  color: #ccc;
}

.popover {
  max-width: 100%;
}

.btn-rounded-circle {
  border-width: 0px;
}

th.text-right.bg-light.rounded-0 {
  visibility: collapse;
}

@media (max-width: 1400px) {
  th.text-right.bg-light.rounded-0 {
    visibility: collapse;
  }
  .c-general > .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .gantt-task-modal {
    max-height: 400px;
  }
  #my-form {
    position: fixed;
    top: 10%;
    left: 12%;
    z-index: 1031;
    width: 75%;
    display: none;
    padding: 10px 10px 10px 10px;
    min-height: 350px;
    max-height: 500px;
  }
  .card {
    height: auto !important;
  }
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 9999;
}

.alert{
  z-index: 3002 !important;
}

.container-select2 .select2 {
  width: intrinsic !important;
  width: -moz-max-content !important;
  width: -webkit-max-content !important;
  min-width: 100% !important;
  word-break: break-all;
  overflow: hidden;
  flex-flow: nowrap;
}

.container-select2 .select2-selection {
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#popover input#telephone {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

span.select2-selection.select2-selection--single {
  min-height: 40px;
  height: fit-content;
}

.form-group .col-md-6:last-child {
  margin-bottom: 0px;
}

.form-group .col-md-6 {
  margin-bottom: 1.375rem;
}

@media (max-width: 767.98px) {
 .modal-header.align-middle {
   white-space: break-spaces;
 } 
 .modal-header {
  white-space: break-spaces;
} 
 th{
    white-space: nowrap; 
 }
 .row.align-items-center.justify-content-center {
     width: calc(103% - 0.1px);
  }
  th.text-right.bg-light.rounded-0 {
   visibility: collapse;
  }
  .col-md-4.text-right {
    padding-top: 10px;
  }
  .modal-body form .card-body {
    padding: 1rem 0.5rem 0;
  }
  .modal table.form-control{
    flex-wrap: nowrap;
    overflow: auto;
  }
  .modal table.form-control td, .modal table.form-control th {
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal table.form-control td:first-child, .modal table.form-control th:first-child {
      padding-left: 0px;
  }
}

.nav-tabs .nav-item {
  white-space: nowrap;
}

@media (max-width: 767.98px) {
  .card-body {
    padding: 1rem;
  }
  .card-header {
    padding: 0.5rem 1rem;
  }
  .content-card .card-body {
    padding-bottom: 0;
  }
  .content-card .card-header, .content-card .card-body {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .card {
   height: auto !important;
  }
  .dropzone.dz-clickable {
    cursor: pointer;
  }
  .modal-body {
    padding: 1rem 0.5rem 0.5rem;
  }
  .modal .close {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1;
  }
}
.dropzone {
  overflow: hidden;
}

div#my-form::before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #12263f26;
  z-index: 0;
}

.card.card-permissions-style tbody td.name{
  width: 100% !important;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Procurar";
}

#menu-backdrop.show {
  opacity: 1;
  visibility: visible;
}

#menu-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #12263f26;
  z-index: 1030;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.table-financed-document td .form-group {
  white-space: nowrap;
  overflow: hidden;
}

.navbar-light .navbar-toggler {
  width: auto;
}

@media (max-width: 1199.98px) {
  th{
    white-space: nowrap; 
   }
   tbody {
    white-space: nowrap;
  }
  .col-auto.text-right.btns-user-configuration{
    width: max-content;
  }
  .card.card-permissions-style tbody td.name{
    max-width: calc(100vw - 100px);
    word-break: break-word !important;
    white-space: inherit;
  }
  
  .card.card-permissions-style tbody td.name span{
    margin: 0 !important;
  }

  #table_data_ td.contracts div, #table_data_ td.name div {
    white-space: nowrap;
  }

  .deliverables-main .card-header .col-auto,
  .payments-main .card-header .col-auto{
    display: block;
    width: 100%;  
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .deliverables-main .card-header .col-auto .btn,
  .payments-main .card-header .col-auto .btn{
    width: 49.5%;
    margin: auto;
    margin: 5px auto!important;
  }

}

@media (max-width: 991.98px) {

  .tabs-loader{
    left: 0 !important;
  }

  #menu-backdrop{
    display: block;
  }

  div#sidebarCollapse {
    position: absolute;
    height: 100vh;
    width: 400px;
    max-width: 100%;
    top: 0;
    right: -100vw;
    padding: 52px 32px 16px;
    background-color: white;
    overflow: auto;
    transition: all 0.2s;
    opacity: 0;
    visibility: hidden;
  }

  div#sidebarCollapse.show {
    opacity: 1;
    right: 16px;
    visibility: visible;
  }

  button.navbar-toggler, .navbar-brand, .navbar-user, .mobile-notifications {
    z-index: 2;
    position: relative;
  }

  .navbar-user{
    margin-right: auto;
  }

  .configure-permissions .card-permissions-style .card-header{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card.card-permissions-style tbody tr, .card.card-permissions-style tbody td {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .avatar{
    display: none;
  }

  .mobile-notifications {
    color: #6e84a3;
    font-size: 20px;
    top: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .navbar-user {
    word-break: normal;
    position: relative;
    overflow: hidden;
    flex-wrap: nowrap;
    max-width: calc(100% - 85px);
  }

  nav.navbar {
    padding: 10px;
  }

  div#collapseUser, .navbar-user button span {
    display: none !important;
  }

}

@media (max-width: 767.98px) {

  .table-responsive table.table.table-sm.card-table td {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .btns-user-configuration{
    display: block !important;
    width: 100% !important;
    margin-top: 5px;
  }
  
  .btns-user-configuration > div {
      width: 49%;
      margin: auto;
      margin-bottom: 3px;
  }
  
  .btns-user-configuration > div .btn {
    width: 100%;
  }
  
  .btns-user-configuration > div {
      width: 100%;
  }
  .dz-message {
    margin: 0 !important;
  }

  .contract-options > div{
    display: none !important;
  }

  .filter_event {
    display: none;
  }
  
}

@media (max-width: 524.98px) {

  .row.mx-0.align-items-center.justify-content-center{
    width: auto;
  }

  .deliverables-main .card-header .col-auto, 
  .payments-main .card-header .col-auto, 
  .endeavorevents-main .card-header .col-auto, 
  .milestones-main .card-header .col-auto, 
  .measurements-main .card-header .col-auto,
  .pricereview-main .card-header .col-auto,
  .endeavorevents-tab .card-header .col-auto {
    margin-top: 5px;
    display: block;
    width: 100%;
  }

  .deliverables-main .card-header .col-auto .btn, 
  .payments-main .card-header .col-auto .btn, 
  .endeavorevents-main .card-header .col-auto .btn, 
  .milestones-main .card-header .col-auto .btn, 
  .measurements-main .card-header .col-auto .btn,
  .pricereview-main .card-header .col-auto .btn,
  .endeavorevents-tab .card-header .col-auto .btn{
    width: 100%;
    margin: 5px 0px 0px auto!important;
  }

  .deliverables-main .card-header .col-auto .btn.btn-success, .deliverables-main .card-header .col-auto .btn.btn-warning, 
  .payments-main .card-header .col-auto .btn.btn-success, .payments-main .card-header .col-auto .btn.btn-warning, 
  .endeavorevents-main .card-header .col-auto .btn.btn-success, .endeavorevents-main .card-header .col-auto .btn.btn-warning, 
  .milestones-main  .card-header .col-auto .btn.btn-success, .milestones-main .card-header .col-auto .btn.btn-warning
  .measurements-main  .card-header .col-auto .btn.btn-success, .measurements-main .card-header .col-auto .btn.btn-warning,
  .pricereview-main  .card-header .col-auto .btn.btn-success, .pricereview-main .card-header .col-auto .btn.btn-warning,
  .endeavorevents-tab .card-header .col-auto .btn.btn-success, .endeavorevents-tab .card-header .col-auto .btn.btn-warning{
      display: none !important;
  }

  .deliverables-main .card-header .row > div.col:first-child, 
  .payments-main .card-header .row > div.col:first-child, 
  .endeavorevents-main .card-header .row > div.col:first-child, 
  .milestones-main .card-header .row > div.col:first-child, 
  .measurements-main .card-header .row > div.col:first-child,
  .pricereview-main .card-header .row > div.col:first-child,
  .endeavorevents-tab .card-header .row > div.col:first-child {
    display: block !important;
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 13px;
  }

  .filter-selector{
    flex: inherit;
    max-width: inherit;
    padding: 0;
    margin-left: auto;
    padding-left: 15px;
  }

  .export-report button, 
  .general-endeavorevents .milestones-main .card-header .col-auto ,
  .pricereview-main .card-header .col-auto,
  .endeavorevents-tab .card-header .col-auto {
    width: 100% !important;
  }
  
  .nav.nav-tabs .nav-item {
    margin: 0;
  }

  .justify-content-end {
    justify-content: flex-start !important;
  }

  .gantt-container {
      width: 100%;
      overflow: auto;
      padding: 20px 10px;
  }

  .gantt-container > div:first-child {
      margin-bottom: 20px;
  }

  #my-form {
    max-width: unset;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
  }

  .navbar-user{
    max-width: 60%;
    margin: auto;
  }

}

.d-revert{
  display: revert !important;
}
.card {
  text-align: left;
}

.confirm-rank-delete > span {
  display: none;
}

.select2-selection.is-invalid{
  border-color: rgb(230, 55, 87);
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E63757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E63757' stroke='none'/%3e%3c/svg%3e"); 
  background-repeat: no-repeat; 
  background-position: right calc(0.375em + 0.25rem) center; 
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.select2.select2-container.select2-container--default.select2-container--disabled.select2-container--focus .select2-selection {
  background-color: #eeeeee !important;
  border-color: #d2ddec !important;
}

.select2-selection_arrow {
  display: block !important;
  opacity: 0.8;
}

span.select2-selection_clear {
  z-index: 1;
  background-color: white;
  right: -1px;
}

.general-additionalWork {
  overflow: hidden;
  margin-right: -15px;
  margin-left: -15px;
}
.general-additionalWork div {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
  height: max-content !important;
}
.general-additionalWork div {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.select2-container--default .select2-selection--multiple .select2-selection_clear{
  margin-top: 0px !important;
}

.modal{
  overflow-y: auto;
  max-height: 100vh;
}

.space-between{
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: space-between;
  -ms-flex-align: space-between;
  align-items: space-between;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#updateContractModal #contract_deposits_guarantee_type, #updateContractModal #contract_deposits_guarantee_percentage{
  display: none !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.nav-tabs-scroll {
  position: relative;
  overflow-x: auto;
}
.nav-tabs-scroll ul {
  overflow-x: revert;
}
.btn {
  height: auto;
}
.btn-primary {
  color: #ffffff;
  background-color: #2c7be5 !important;
  border-color: #2c7be5 !important;
}
.btn-danger {
  color: #ffffff;
  background-color: #e63757 !important;
  border-color: #e63757 !important;
}
.btn-success {
  color: #ffffff;
  background-color: #00d97e !important;
  border-color: #00d97e !important;
}

#table_data_length label, #table_data_filter label {
  display: inline-flex;
  align-items: center;
}
#table_data_filter {
  text-align: right;
}
#table_data_length, #table_data_filter {
  padding: 10px 24px;
  padding-right: 24px;
}
.pagination {
  width: max-content;
  margin: 20px auto;
  max-width: 100%;
}
#table_data_wrapper{
  overflow-x: hidden;
}

.endeavor_event .avatar {
  display: block !important;
}

#monthsFormulas td {
  vertical-align: top;
}

#monthsFormulas td:first-child {
  width: 25%;
  text-align: center;
}

#processing_data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}
#processing_data.loading{
  cursor: wait;
}
#processing_data.show{
  visibility: visible;
  opacity: 1;
}
#processing_data > div {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

#milestones-list > div:first-child{
  filter: blur(2px)
}

#load_deviations {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  width: fit-content;
}

.noBlur, .noBlur > div, .noBlur > div:first-child{
  filter: unset !important;
}


.pagination, #table_data_paginate, #table_data_paginate span {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 20px auto;
  max-width: 100%;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
}
.page-link, #table_data_paginate .paginate_button, #table_data_paginate .ellipsis {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #12263f;
  background-color: #ffffff;
  border: 1px solid #e3ebf6;
  border-radius: 0;
}
.page-item.active .page-link, #table_data_paginate .paginate_button.current {
  z-index: 3;
  color: #ffffff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}
.page-item:first-child .page-link, #table_data_paginate a#table_data_previous {
  margin-left: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.page-item:last-child .page-link, #table_data_paginate a#table_data_next {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.page-item.disabled .page-link, #table_data_next.disabled, #table_data_previous.disabled {
  color: #95aac9;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #e3ebf6;
}
#table_data_paginate span{
  margin: 0;
}
li.paginate_button {
  padding: 0 !important;
  border: 0 !important;
}
#table_data_length, #table_data_filter {
  display: inline-flex;
  width: 50%;
  vertical-align: middle;
}
#table_data_filter label {
  margin-left: auto;
  text-align: right;
}
.col-sm-12 #table_data_filter {
  width: 100%;
}
#table_data_paginate a{
  cursor: pointer;
}
.custom-control-label::before, .custom-file-label, .custom-select, [name="table_data_length"] {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-select-sm, [name="table_data_length"] {
  height: calc(1.75em + 0.25rem + 2px);
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.5rem;
  font-size: 0.8125rem;
}
.custom-select, [name="table_data_length"] {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b506c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
      background-color: rgb(255, 255, 255);
      background-clip: border-box;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-control-sm, [name="table_data_length"], [type="search"] {
  height: calc(1.75em + 0.25rem + 2px);
  padding: 0.125rem 0.9rem;
  font-size: 0.8125rem;
  line-height: 1.75;
  border-radius: 0.25rem;
}
.form-control, [type="search"] {
  display: block;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#list-pagination button {
  display: inline-flex;
}
.btn-primary {
  color: #ffffff !important;
  background-color: #2c7be5 !important;
  border-color: #2c7be5 !important;
}
.btn-success, .bg-success {
  color: #ffffff !important;
  background-color: #00d97e !important;
  border-color: #00d97e !important;
}
.tabs-loader {
  position: fixed;
  top: 50%;
  left: 250px;
  right: 0;
  transform: translateY(-50%);
}

.create-notification-btn {
  color: #2c7be5;
  font-size: 1.0rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-color: blue;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border: solid;
  border-color: #2c7be5;
  border-width: 1px;
  cursor: pointer;
}

.create-notification-modal{
    width: 20%;
    position: fixed;
    right: 2%;
    bottom: 3%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    border: 1px solid rgba(18, 38, 63, 0.1);
    background-clip: padding-box;
    border-radius: 0.5rem;
    outline: 0;
    z-index: 10;
}

span.select2.is-invalid .selection span {
    border-color: rgb(230, 55, 87);
    padding-right: calc(1.5em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E63757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E63757' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.25rem) center;
    background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default .select2-selection--multiple {
  padding: 0rem 0.75rem 0rem !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 0.5rem 0 .25rem .25rem !important;
}

.bg-secondary {
  background-color: #6e84a3 !important;
}

.vue-dropzone.dropzone {
  display: block;
}

.vue-dropzone.dropzone.dz-started::before {
  content: 'Adicionar mais';
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #c8c8c8;
}

.object-contain{
  object-fit: contain;
}
.object-cover{
  object-fit: cover;
}
.position-center{
  object-position: center;
}
.select2-selection__placeholder {
  color: #b1c2d9 !important;
}

.w-max-content{
  width: -moz-max-content !important;
  width: -webkit-max-content !important;
  width: -o-max-content !important;
  width: -ms-max-content !important;
  width: max-content !important;
}


// Full calendar
.fc-left h2 {
  text-transform: capitalize;
}
.fc-button{
  color: #ffffff !important;
  background: #2c7be5 !important;
  border-color: #2c7be5 !important;
  opacity: 1 !important;
}
.fc-button:hover{
  background: #1f65c4 !important;
}
.fc-content {
  padding: 2px;
  max-height: 50px;
}
.fc-title {
  white-space: break-spaces;
}

.fc_colors_legend{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 15px;
  padding: 0 10px;
}
.fc_colors_legend_item{
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.fc_colors_legend_indicator{
  position: relative;
  display: inline-flex;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 5px;
}
.fc-event-container {
  max-height: 250px;
  overflow: auto;
}

@media (min-width: 991.98px) {
  .navbar.navbar-vertical.short {
    padding: 0px;
    max-width: 70px !important;

    .nav-link i{
      text-align: center !important;
    }
    .navbar-brand {
      padding: 5px !important;
    }
    .nav-link span, #tech-area-btn span, .btn-collaps-user span {
      display: none !important;
    }
    .navbar-user{
      padding: 10px 0;
    }
    .navbar-user, .navbar-nav .nav-link > .fe, #tech-area-btn i {
      margin: auto !important;
    }
    #tech-area-btn, .scale_sidebar_btn {
      width: max-content !important;
      margin: auto;
      text-align: center;
    }
    + .main-content{
      margin-left: 70px;
    }
    #sidebarCollapse{
      padding: 0;
      margin: 0;
    }
  }
  .navbar-vertical.navbar-expand-lg.short .navbar-nav{
    padding: 0 !important;
    margin: 0 !important;
  }

  .scale_sidebar_btn{
    color: #6e84a3;
    padding: 0px !important;
    margin-bottom: 10px;
  }
  .scale_sidebar_btn:hover, .scale_sidebar_btn:hover *{
    color: #12263f;
  }
  .scale_sidebar_btn > .fe {
    display: inline-block;
    min-width: 1.75rem;
    padding-bottom: .125em;
    font-size: 1.0625rem;
    line-height: 1.40625rem;
  }
}

@media (max-width: 991.98px) {
  #shorten_menu {
    display: none !important;
  }
}


.create-notification-div .position-fixed {
  z-index: 10;
  bottom: 30px;
  right: 30px;
}

.table-scroll tbody{
  overflow-x: auto !important;
  height: 250px;
  display: block;
}
.table-scroll{
  thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
  }
  thead, tbody tr td{
    white-space: normal;
  }
}

.striped-list div:nth-of-type(2n+1) > .card {
  background-color: #f9fbfd;
}

.v-space-between{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.select2-container--disabled .select2-selection.select2-selection--multiple,
.select2.select2-container.select2-container--default.select2-container--disabled.select2-container--focus .select2-selection {
  background-color: #edf2f9 !important;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color: white !important;
}

.select2-selection__choice.option-fixed {
  background-color: #c9cdd3 !important;
}

.flu:first-letter {text-transform: uppercase}

.restrict-select-length .select2 {
  white-space: nowrap;
  overflow: hidden;
}