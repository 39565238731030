// Bootstrap functions
@import '../../../node_modules/bootstrap/scss/functions';
@import "feather.css";
@import "timeline.css";
@import "select2.css";
@import "select2Alt.css";
@import "flatpickr.css";
// Dashkit variables
@import 'dashkit/variables';
// Bootstrap core
@import '../../../node_modules/bootstrap/scss/bootstrap';
// Dashkit core
@import 'dashkit/dashkit';
@import "flatpickr";
@import "gantt/dhtmlxgantt";
@import "gantt/dhtmlxgantt_material";
@import "custom";